import React, { Component } from "react";
import { apiURL, teacherApiURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import SearchIcon from "@material-ui/icons/Search";
import Helmet from "react-helmet";
import Delete from "../delete/Delete";
//import "./editor.js";
import EditIcon from "@material-ui/icons/Edit";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import "../style/Style.css";
//import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import Meta from "../meta/Meta";
import XLSX from "xlsx";
import ExportExcelButton from "../utils/ExportExcelButton";

const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = (theme) => ({
  buttonEdit: {
    textTransform: "capitalize",
    border: "1px solid #035eab",
    borderRadius: "0px",
    marginRight: "10px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 650,
  },
});

class Faq extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      // drawerOpen: false,
      drawerOpen: true,
      showDialog: false,
      faqData: [],
      page: 1,
      per: 20,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      mode: "",
      editorVlaue: "",
      language: "",
      subject: "",
      snackBarTimer: "",
      searchValue: "",
      noData: "",
      delete: false,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.faqAddDialog = this.faqAddDialog.bind(this);
    this.faqDialogClose = this.faqDialogClose.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.editDialogOpen = this.editDialogOpen.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  changeHandler = (e, type) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.name +'===='+e.target.value);
  };
  handleImageChange = (e) => {

    if (!e.target.files.length) return; // Prevent errors if no file is selected
  
    const file = e.target.files[0];
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
  
    if (fileSizeMB > 5) {
      this.setState({
        showSnackbar: true,
        snackBarMessage: "Image should not be more than 5MB",
        severityColor: "error",
        snackBarTimer: 3000,
      });
      return;
    }
    
    this.setState({
      imagePreview: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
      imageEdit: true,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.faqListData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  faqListData = () => {
    this.setState({ loading: true,noData: "" });
    const { per, page } = this.state; // Removed faqData from destructure since we're resetting it on search
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      search: this.state.searchValue, // Use state value directly here
    };
  
    // Reset faqData if it's a new search
    if (page === 1) {
      this.setState({ faqData: [] }); // Clear previous results on new search
    }
  
    axios
      .post(teacherApiURL("faq?per_page=" + per + "&page=" + page), body, { headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState((prevState) => ({
              faqData: page === 1 ? resultData.result : [...prevState.faqData, ...resultData.result], // Reset on first page, append on subsequent pages
              scrolling: false,
              total_pages: resultData.total_pages,
            }));
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({
              loading: false,
              scrolling: false,
              noData: "No FAQ Found",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };
  

  loadMore = () => {
    if (this._isMounted) {
      const { page, total_pages } = this.state;
      if (page < total_pages) {
        this.setState(
          (prevState) => ({
            page: prevState.page + 1,
            scrolling: true,
          }),
          () => {
            this.faqListData(); // Call the data fetching function
          }
        );
      }
    }
  };
  
  handleScroll = () => {
    if (this._isMounted) {
      const tableElement = document.querySelector("Table");
      if (tableElement !== null) {
        const lastRow = tableElement.lastElementChild; // Get the last row in the table
        if (lastRow) {
          const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
          const pageOffset = window.pageYOffset + window.innerHeight;
  
          if (pageOffset > lastRowOffset && this.state.scrolling) {
            this.loadMore(); // Trigger loadMore if at the bottom
          }
        }
      }
    }
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    //e.stopPropagation();
    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("description", this.state.editorVlaue);
    formData.append("language", this.state.language);
    formData.append("subject", this.state.subject);

    const faqBody={
      title:this.state.title,
      description:this.state.editorVlaue,
      language:this.state.language,
      subject:this.state.subject
    }
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(teacherApiURL("faq/add"), faqBody, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.faqDialogClose();
          // this.componentDidMount();
          window.location.reload();
          this.setState({ imageEdit: false, imagePreview: null, file: null });
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onEditFormSubmit = (e) => {
    //alert(this.state.name);
    e.preventDefault();
    const editFormData = new FormData();
    editFormData.append("id", this.state.id);
    editFormData.append("title", this.state.title);
    editFormData.append("description", this.state.editorVlaue);
    editFormData.append("language", this.state.language);
    editFormData.append("subject", this.state.subject);

    const faqEditBody={
      id:this.state.id,
      title:this.state.title,
      description:this.state.editorVlaue,
      language:this.state.language,
      subject:this.state.subject
    }
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(teacherApiURL("faq/edit"), faqEditBody, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ imageEdit: false, imagePreview: null, file: null });
          this.faqDialogClose();
          // this.componentDidMount();
          window.location.reload();
          this.faqListData();
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeStatus = (id, status) => {
    const changeStatus= status == 1 ? 0 : 1;
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(teacherApiURL("faq/change_status/" + id + "/" + changeStatus), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedfaq = this.state.faqData.map((faq) => {
            if (faq.faq_fldi_id === id) {
              const _faq = { ...faq };
              _faq.faq_flg_status = status == 0 ? 1 : 0;
              return _faq;
            }
            return faq;
          });
          this.setState({ faqData: updatedfaq });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  faqAddDialog = (e) => {
    this.setState({
      showDialog: true,
      mode: "add",
      title: "",
      editorVlaue: "",
      language: "",
      subject: "",
    });
  };

  editDialogOpen = (e) => {
    this.setState({
      showDialog: true,
      mode: "edit",
      id: e.faq_fldi_id,
      title: e.faq_fldv_title,
      editorVlaue: e.faq_fldt_description,
      language: e.faq_fldv_language,
      subject: e.faq_fldv_subject,
    });
  };

  faqDialogClose = () => {
    this.setState({ showDialog: false });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      editorVlaue: content,
    });
  };

  searchHandler = (e) => {
    this.setState({page: 1, searchValue:e.target.value}, () => { 
        this.faqListData();  
      })
    }

  successDelete = (e, id, index) => {
    if (e === true) {
      var deleteFaq = this.state.faqData;
      deleteFaq.splice(index, 1);
      this.setState({
        faqData: deleteFaq,
        showSnackbar: true,
        snackBarMessage: "data deleted suceesfully",
        severityColor: "success",
        snackBarTimer: 3000,
      });
    }
  };

  exportFile() {
    let headers = [["Title", "Status", "Delete"]];
    this.state.faqData.forEach((value) => {
      let valueArray = [
        value.faq_fldv_title,
        value.faq_flg_status === 1 ? "Active" : "Deactive",
        value.faq_flg_is_deleted === 1 ? "Not Delete" : "Deleted",
      ];
      headers.push(valueArray);
    });

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.book_append_sheet(wb, wsAll, "FAQ's");
    XLSX.writeFile(wb, "export-faq.xlsx");
  }

  render() {
    const { classes } = this.props;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div>
        <Helmet>
          <title>Faq</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      <TextField
                        className="textField"
                        type="text"
                        placeholder="...Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          style: {
                            color: "#414141",
                            backgroundColor: "rgba(255, 255, 255, 0.29)",
                          },
                        }}
                        size="small"
                        id="search"
                        variant="outlined"
                        style={{ fontColor: "#fff", marginRight: "1rem" }}
                        name="search"
                        onChange={(e) => this.searchHandler(e)}
                      />
                      {/*<Searchbar onChange={this.faqListData} />*/}
                      <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={this.faqAddDialog}
                      >
                        {" "}
                        Add Faq{" "}
                      </Button>
                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      />
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>Faq </h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableCell style={{ width: "2rem" }}>Id</TableCell>
                          <TableCell> Title</TableCell>
                          {/*<TableCell>Description</TableCell> */}
                          <TableCell style={{ width: "3rem" }}>
                            Status
                          </TableCell>
                          <TableCell style={{ width: "3rem" }}>
                            Action
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {this.state.faqData.map((arrList, index) => {
                            return (
                              <TableRow key={arrList.faq_fldi_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{arrList.faq_fldv_title}</TableCell>
                                {/*<TableCell>{sanitizeHtml(arrList.fldt_description)}</TableCell> */}
                                <TableCell>
                                  {" "}
                                  <Switch
                                    size="small"
                                    checked={arrList.faq_flg_status == 1}
                                    color="primary"
                                    onChange={() =>
                                      this.changeStatus(
                                        arrList.faq_fldi_id,
                                        arrList.faq_flg_status
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    className={classes.buttonEdit}
                                    variant="outlined"
                                    color="default"
                                    startIcon={
                                      <EditIcon
                                        style={{
                                          color: "#035eab",
                                          fontSize: "1rem",
                                        }}
                                      />
                                    }
                                    onClick={() => this.editDialogOpen(arrList)}
                                  >
                                    Edit
                                  </Button>
                                  <Delete
                                    deleteID={arrList.faq_fldi_id}
                                    table={"faq"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.faq_fldi_id,
                                        index
                                      )
                                    }
                                  />
                                  {/* <Meta
                                    primaryId={arrList.faq_fldi_id}
                                    useFor={"inner_page"}
                                    table={"faq"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.faq_fldi_id,
                                        index
                                      )
                                    }
                                  /> */}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                  >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.noData ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>

        <Dialog
          open={this.state.showDialog}
          fullWidth={true}
          maxWidth={"md"}
          disableEnforceFocus
        >
          <form
            id="courses"
            onSubmit={
              this.state.mode === "edit"
                ? (e) => this.onEditFormSubmit(e)
                : (e) => this.onFormSubmit(e)
            }
          >
            <DialogTitle id="form-dialog-title">
              <h3 style={{ textAlign: "center" }}>
                {this.state.mode === "add" ? "Add" : "Edit"} faq
              </h3>
            </DialogTitle>
            <DialogContent style={{ overflowY: "hidden" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Title"
                    variant="outlined"
                    name="title"
                    size="small"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.title}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                      >
                        <InputLabel>Language</InputLabel>
                        <Select
                          name="language" // Adjust the name to be "language"
                          label="Language"
                          required
                          value={this.state.language}
                          onChange={(e) => this.changeHandler(e)}
                          //renderValue={(selected) => (selected).join(', ')}
                        >
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Marathi">Marathi</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                      >
                        <InputLabel>Subject</InputLabel>
                        <Select
                          name="subject" // Adjust the name to be "language"
                          label="Subject"
                          required
                          value={this.state.subject}
                          onChange={(e) => this.changeHandler(e)}
                          //renderValue={(selected) => (selected).join(', ')}
                        >
                          <MenuItem value="General">General</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Mathematics">Mathematics</MenuItem>
                          <MenuItem value="Science">Science</MenuItem>
                          <MenuItem value="Hindi">Hindi</MenuItem>
                          <MenuItem value="Marathi">Marathi</MenuItem>
                          <MenuItem value="History">History</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Editor
                    initialValue={this.state.editorVlaue}
                    init={{
                      height: 188,
                      selector: "textarea", // change this value according to your HTML
                      convert_urls: false,
                      menubar: true,
                      plugins: [
                        "advlist autolink tinydrive lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      api_key:
                        "v2xbwrmnf5ixj35bcuymns7odhg03cvh4xuyud4wc0zjc0lh",
                      tinydrive_token_provider:
                        "https://navdishaadmin.navneet.com/jwt/text_editor_key/api/jwt.php",
                      toolbar:
                        "undo redo | formatselect | bold italic underline backcolor | \
                                         alignleft aligncenter alignright alignjustify | \
                                         bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(e) => this.handleEditorChange(e)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="buttonCancel">
              <Button
                variant="contained"
                onClick={() => this.faqDialogClose()}
                color="secondary"
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {" "}
                Submit{" "}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(useStyles)(Faq);