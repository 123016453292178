import React, { Component } from "react";
import { apiURL, teacherApiURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import { TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import Moment from "moment";

const drawerWidth = 280;

const useStyles = (makeStyles) => ({
  delButton: {
    textTransform: "capitalize",
    border: "1px solid #f26522",
    borderRadius: "0px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  closeButton: {
    padding: 2,
    fontSize: 24,
  },
  selectField: {
    padding: "0rem",
  },
});

class UserList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      teacherData: [],
      page: 1,
      per: 20,
      totalPages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      searchValue: "",
      noData: "",
      filteredTeacherData: [],
    };
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  componentWillMount() {
    this._isMounted = true;
    this.teacherListData();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  teacherListData = (isLoadMore = false) => {
    this.setState({ loading: true });

    const { per, page, teacherData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };

    const webinar_id = this.props.location.state ? this.props.location.state.webinar_id : undefined;
    const body = {
      webinar_id: webinar_id || "",
    };

    const updatedPage = isLoadMore ? page : 1;

    if (!isLoadMore) {
      this.setState({ teacherData: [] });
    }

    axios
      .post(
        teacherApiURL(`teacher/get_teacher_list_visiting_card?per_page=${per}&page=${updatedPage}`),
        body,
        { headers: headers }
      )
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;

          if (resultData.status === true) {
            this.setState({
              teacherData: isLoadMore ? [...teacherData, ...resultData.result] : resultData.result,
              totalPages: resultData.total_pages,
              page: updatedPage + 1,
              loading: false,
              showSnackbar: true,
              snackBarMessage: 'Teacher list fetched successfully!',
              severityColor: 'success',
            });
          } else {
            this.setState({
              loading: false,
              showSnackbar: true,
              snackBarMessage: resultData.message || 'No Records Found',
              severityColor: 'warning',
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching teacher list:", error);
        this.setState({
          loading: false,
          showSnackbar: true,
          snackBarMessage: 'Error fetching data, please try again',
          severityColor: 'error',
        });
      });
  };

  loadMore = () => {
    if (this._isMounted && !this.state.loading && this.state.page <= this.state.totalPages) {
      this.setState({ loading: true }, () => this.teacherListData(true));
    }
  };

  handleScroll = () => {
    const { page, totalPages, loading } = this.state;

    if (!loading && page <= totalPages) {
      const scrollY = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollY + windowHeight >= documentHeight - 50) {
        this.loadMore();
      }
    }
  };

  render() {
    const { page, per, teacherData, loading, noData } = this.state;

    const startIndex = (page - 1) * per + 1;

    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };

    const loadingTextCSS = { display: loading ? "block" : "none" };

    return (
      <React.Fragment>
        <Helmet>
          <title>Registered Teacher</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={<div />}
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>User List</h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email Address</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Subject</TableCell>
                            <TableCell>School/College</TableCell>
                            <TableCell>Board & Medium</TableCell>
                            <TableCell>Grades Taught/Managed</TableCell>
                            <TableCell>Card Downloaded Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {teacherData.map((arrList, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{arrList.fldv_first_name || arrList.fldv_name}</TableCell>
                              <TableCell>{arrList.fldv_email}</TableCell>
                              <TableCell>{arrList.fldv_contact}</TableCell>
                              <TableCell>{arrList.fldi_city}</TableCell>
                              <TableCell>{arrList.fldv_subjects_taught || arrList.fldv_subject}</TableCell>
                              <TableCell>{arrList.fldv_school_college}</TableCell>
                              <TableCell>{arrList.fldv_board_medium}</TableCell>
                              <TableCell>{arrList.fldv_grades_taught}</TableCell>
                              <TableCell>{Moment(arrList.fldv_visiting_card_down_date).format("D MMMM, YYYY")}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                  >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{!loading && teacherData.length === 0 ? noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>
        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(UserList);
