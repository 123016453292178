import React, { Component } from 'react';
import { apiURL, change_date_format, teacherApiURL } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { makeStyles, withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, InputAdornment} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';  
import Helmet from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react'; 
import Delete from "../delete/Delete";
import ClearIcon from '@material-ui/icons/Clear';

import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";  
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';

const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });
 
 class Testimonial extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            showDialog: false, 
            teamData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false, 
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            imagePreview: null,
            file: null,
            imageEdit: false,
            mode: '',  
            selecteDate:  new Date(),
            changeDate: '', 
            editorVlaue: '',
			noData:''
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.addDialog              = this.addDialog.bind(this);
        this.dialogClose            = this.dialogClose.bind(this);
        this.changeHandler          = this.changeHandler.bind(this);
        this.onFormSubmit           = this.onFormSubmit.bind(this);
        this.onEditFormSubmit       = this.onEditFormSubmit.bind(this);
        this.editDialogOpen         = this.editDialogOpen.bind(this); 
        this.changeStatus           = this.changeStatus.bind(this)
        this.handleEditorChange     = this.handleEditorChange.bind(this);
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }

    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
        }); 
    }

    handleEditorChange = (content, editor) => { 
        this.setState({
            editorVlaue: content
        });
      }

    handleDateChange = (date) => { 
        console.log(date) 
        this.setState({
            selecteDate: new Date(date), 
            changeDate: change_date_format(date)
        });
    }

    handleImageChange = (e) => {
        if (!e.target.files.length) return; // Prevent errors if no file is selected
  
        const file = e.target.files[0];
        const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
      
        if (fileSizeMB > 5) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Image should not be more than 5MB",
            severityColor: "error",
            snackBarTimer: 3000,
          });
          return;
        }
  
        this.setState({  
            imagePreview: URL.createObjectURL(e.target.files[0]),
            file:e.target.files[0],
            imageEdit: true
         })
    }

    removeImage = (e) => {
        this.setState({  
            imagePreview: "",
            file:"",
            imageEdit: false,
            image: ""
         })
    }


    componentDidMount() { 
        this._isMounted = true; 
        this.teamListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false; 
        window.removeEventListener("scroll", this.handleScroll);
    }
  
    teamListData = () => {
        this.setState({ loading: true });
    
        const { per, page, teamData } = this.state; 
        const headers = { 'Content-type': 'application/json' };
        const body = {
            "search": document.getElementById('search').value
        };
    
        axios.post(teacherApiURL(`testimonials?per_page=${per}&page=${page}`), body, { headers })
            .then(response => {
                if (this._isMounted) {
                    const resultData = response.data;
    
                    if (resultData.status === true || resultData.status === 1) {
                        this.setState({
                            teamData: (resultData.total < 20) ? resultData.result : [...teamData, ...resultData.result],
                            scrolling: true,
                            total_pages: resultData.total_pages,
                            loading: false
                        });
                    } else {
                        if (teamData.length === 0) {
                            this.setState({ loading: false, scrolling: false,teamData : [], noData: "No Testimonial Found" });
                        } else if(resultData.message ==="no data found" && resultData.status === false) {
                            this.setState({ loading: false, scrolling: false ,teamData : [] , noData: "No Testimonial Found" });
                        } else{
                            this.setState({ loading: false, scrolling: false ,teamData : [] });
                        }

                    }
                }
            })
            .catch(error => {
                console.error("Error fetching testimonials:", error);
                this.setState({ loading: false, scrolling: false, noData: "Failed to load data" });
            });
    };
    

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.teamListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    onFormSubmit = (e) => { 
        e.preventDefault();
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json',
        }
        const formData  = new FormData();
        formData.append('file', this.state.file);
        formData.append('title', this.state.title);
        formData.append('name', this.state.name);
        formData.append('description', this.state.editorVlaue);  
        
        axios.post(teacherApiURL('testimonials/add'), formData, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.dialogClose();
                this.componentDidMount();
                this.setState({ imageEdit: false, imagePreview: null, file: null  });  
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                     showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
            
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    onEditFormSubmit = (e) => {
         e.preventDefault(); 
         const headers = {
             'content-type': 'application/json',
             'Accept': 'application/json'
         }
        
         const editFormData  = new FormData();
         editFormData.append('id', this.state.id);
         editFormData.append('name', this.state.name);
         editFormData.append('title', this.state.title);
         editFormData.append('description', this.state.editorVlaue); 
         editFormData.append('exist_file', this.state.image);
         editFormData.append('image_change', this.state.imageEdit);
         if(this.state.file !== null){
             editFormData.append('image', this.state.file);
         }

         axios.post(teacherApiURL('testimonials/edit'),editFormData, {headers: headers})
         .then(response => {
             const resultData = response.data; 
             if(resultData.status === true || resultData.status === 1)
             {
                 this.setState({ imageEdit: false, imagePreview: null, file: null });
                 this.dialogClose();
                 this.componentDidMount();  
                 this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                     showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
         })
         .catch(error => {
             console.log(error)
         })
    }

    changeStatus = (id, status) => { 
        const statusChange= status === 1 ? 0 : 1;
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(teacherApiURL('testimonials/change_status/'+id+'/'+statusChange), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updatedTeam = this.state.teamData.map(team=>{
                    if(team.fldi_id === id){
                        const _team = {...team};
                        _team.flg_status = status=== 0 ? 1 : 0;
                        return _team;
                    }
                    return  team;
                });
                this.setState({ teamData: updatedTeam, showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000});
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

    addDialog = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'add', 
            name: '',
            title: '',
            file: '',
            image:'',
            editorVlaue:''
        });
    }

    editDialogOpen = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'edit',
            id: e.fldi_id,
            name: e.fldv_name,
            title: e.fldv_title,
            image: e.fldv_image_url,
            editorVlaue: e.fldt_description,
        });  
    }

    dialogClose = () => { 
        this.setState({ showDialog: false });
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value}, () => { 
            this.teamListData();  
          })
        //this.teamListData();
    }

    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteData = this.state.teamData;
            deleteData.splice(index, 1);
            this.setState({
                teamData: deleteData, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 
    }

    exportFile() {
        let headers = [["Name", "Title", "Image", "Status", "Delete"]]
        this.state.teamData.forEach((value) => {
          let valueArray = [value.fldv_name, value.fldv_title, value.fldv_image_url, (value.flg_status === 1)?"Active":"Deactive", (value.flg_is_deleted === '1')?"Not Delete":"Deleted"]
          headers.push(valueArray)
        })
    
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(headers)
        
        XLSX.utils.book_append_sheet(wb, wsAll, "Testimonial")
            XLSX.writeFile(wb, "export-testimonial.xlsx")
      }
   
   
    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" }; 
        return(
            <div>
                <Helmet>
                    <title>Testimonials</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" > 
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div>
                                    <TextField type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    /> 
                                    <Button className={classes.buttonAdd} variant="contained" color="primary" startIcon={<AddCircleOutlineOutlinedIcon/>} onClick={this.addDialog}> Add Testimonial </Button>  
                                    <ExportExcelButton clickProp={(e) => this.exportFile(e)}/>
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Testimonial</h2>
                        </Alert>
                        </Box>
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead>     
                                            <TableRow>
                                                <TableCell style={{width:'2rem'}}>Id</TableCell> 
                                                <TableCell> Name </TableCell> 
                                                <TableCell> Title </TableCell> 
                                               {/*} <TableCell> Image </TableCell>  */}
                                                <TableCell style={{width:'3rem'}}>Status</TableCell>
                                                <TableCell style={{width:'3rem'}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.teamData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell> 
                                                    <TableCell>{arrList.fldv_name}</TableCell> 
                                                    <TableCell>{arrList.fldv_title}</TableCell> 
                                                    {/*<TableCell><img style={{width:"40px"}} src={arrList.fldv_image_url} alt={arrList.fldv_name} /></TableCell>*/}
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status===1} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell>
                                                        <Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<EditIcon style={{color:'#035eab', fontSize:'1rem',}} />} onClick={() => this.editDialogOpen(arrList)}>Edit</Button>
                                                        <Delete deleteID={arrList.fldi_id} table={'testimonials'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                    </TableCell>
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table> 
                                    </TableContainer>
                                    </Box>
                              
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{this.state.noData && this.state.teamData.length === 0 ? this.state.noData : ""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'md'} disableEnforceFocus>
                    <form  id="courses" ref={c => { this.form = c }} onSubmit={this.state.mode === 'edit'?(e) => this.onEditFormSubmit(e):(e) => this.onFormSubmit(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>{(this.state.mode === 'add')?'Add':'Edit'} Testimonial</h3></DialogTitle>
                        <DialogContent style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Name" variant="outlined" name="name"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.name}  />
                                </Grid>
                                
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Title" variant="outlined" name="title"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.title}  />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                <Editor
                                     initialValue={this.state.editorVlaue}
                                     init={{
                                     height: 188,
                                     selector: 'textarea',  // change this value according to your HTML
                                    convert_urls: false,
                                     menubar: true,
                                   
                                     plugins: [
                                         'advlist autolink tinydrive lists link image charmap print preview anchor',
                                         'searchreplace visualblocks code fullscreen',
                                         'insertdatetime media table paste code help wordcount'
                                     ],
                                     api_key: 'v2xbwrmnf5ixj35bcuymns7odhg03cvh4xuyud4wc0zjc0lh',
                                     tinydrive_token_provider: 'https://navdishaadmin.navneet.com/jwt/text_editor_key/api/jwt.php',
                                     toolbar:
                                         'undo redo | formatselect | bold italic underline backcolor | \
                                         alignleft aligncenter alignright alignjustify | \
                                         bullist numlist outdent indent | removeformat | help'
                                     }}
                                    onEditorChange={(e) => this.handleEditorChange(e)}
                                />
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>    
                                    
                                {(this.state.imagePreview || this.state.image)?<ClearIcon  onClick={(e) => this.removeImage(e)} />: ""}
                                {this.state.imageEdit === true?
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.imagePreview}/>:
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.image}/>
                                    }
                                    <Button variant="contained" startIcon={<ImageSearchIcon/>} color="primary" component="label">Choose Image
                                        <input type="file" style={{ display: "none" }} onChange={this.handleImageChange} />
                                    </Button> 
                                   
                                </Grid>  
                             </Grid>
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.dialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Testimonial);